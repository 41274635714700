<template>
    <div>
      <vs-button color="success" @click="handleDetail()">Create</vs-button>
      <div class="vx-row mb-12">
        <div :class="[detail ? 'core vx-col w-full mb-base md:w-1/2' : '', 'core vx-col w-full mb-base md:w-1/1']">
          <vs-table
            search
            stripe
            border
            description
            :sst="true"
            :data="table.data"
            :max-items="table.length"
            :total="table.total"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
          >
            <template slot="header">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item
                    v-for="item in table.limits"
                    :key="item"
                    @click="handleChangelength(item)"
                    ><span>{{ item }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </template>
            <template slot="thead">
              <vs-th sort-key="">Action</vs-th>
              <vs-th sort-key="">Warehouse</vs-th>
              <vs-th sort-key="">Item</vs-th>
              <vs-th sort-key="">Days >=</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="whitespace-no-wrap">
                  <div class="flex flex-row space-x-1">
                      <vs-button
                          color="warning"
                          icon-pack="feather"
                          @click="handleDetail(data[indextr].id)"
                          icon="icon-edit"
                      />
                      <vs-button
                          color="danger"
                          icon-pack="feather"
                          @click="handleDelete(data[indextr].id)"
                          icon="icon-trash"
                      />
                  </div>
                </vs-td>
                <vs-td :data="data[indextr]">{{ data[indextr].warehouse.code }} {{ data[indextr].warehouse.name }}</vs-td>
                <vs-td :data="data[indextr]">{{ data[indextr].item.sku_code }} {{ data[indextr].item.name }}</vs-td>
                <vs-td :data="data[indextr]">{{ data[indextr].in_days }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            style="padding-top: 5px"
            :total="table.totalPage"
            v-model="setPage"
          />
        </div>
        <transition name="detail-fade">
          <div class="vertical-divider core vx-col w-full mb-base" v-if="detailS" :class="[detail ? 'md:w-1/2' : 'close md:w-1/1']">
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto min-h-0"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col class="flex flex-row align-middle min-h-0">
              <div class="flex items-center w-9">
                  <vs-button
                      size="small"
                      v-on:click="handleClose"
                      color="grey"
                      icon-pack="feather"
                      icon="icon-x-square"
                  ></vs-button>
              </div>
              <div class="flex-1 m-2">
                <div class="font-bold">{{ detailData.id ? "Edit" :  "Create" }}</div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full">
                    <div class="text-sm">Warehouse</div>
                    <multiselect
                      v-model="detailData.warehouse"
                      :options="warehouses"
                      placeholder="Type to search"
                      track-by="code"
                      label="text"
                      :max-height="125"
                      :internal-search="false"
                      @search-change="handleSearchChangeWarehouse"
                      @clear="handleSearchClearWarehouse"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <!-- <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.code }}</span>
                        </span>
                      </template>
  
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.code }}</span>
                        </div>
                      </template> -->
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full">
                    <div class="text-sm">Item</div>
                    <multiselect
                      v-model="detailData.item"
                      :options="items"
                      placeholder="Type to search"
                      track-by="sku_code"
                      label="text"
                      :max-height="125"
                      :internal-search="false"
                      @search-change="handleSearchChangeItem"
                      @clear="handleSearchClearItem"
                    >
                      <span slot="noResult">Oops! No data found</span>
                      <!-- <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.sku_code }}</span>
                        </span>
                      </template>
  
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.sku_code }}</span>
                        </div>
                      </template> -->
                    </multiselect>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="vx-col w-full">
                    <vs-input
                      class="w-full"
                      label="Days >="
                      name="in_days"
                      v-model="detailData.in_days"
                      @keypress="isNumber($event)"
                    />
                  </div>
                </div>
                <vs-button @click="handleSave()">Save</vs-button>
              </div>
            </vs-col>
          </div>
          <div v-else></div>
        </transition>
      </div>
    </div>
  </template>
  
  <script>
  // import axios from "axios"
  export default {
    name: "",
    data() {
      return {
        // cancelTokenItem: this.$http.CancelToken.source(),
        controllerWarehouse: new AbortController(),
        controllerItem: new AbortController(),
        warehouses: [],
        items: [],
        detailS: false,
        detail: false,
        detailData: {},
        table: {
          data: [],
          length: 10,
          page: 1,
          search: "",
          order: "id",
          sort: "desc",
          total: 0,
          totalPage: 1,
          totalSearch: 0,
          limits: [10, 25, 50, 100, "All"],
          start: 1,
          end: 0,
        }
      }
    },
    computed: {
      setPage: {
        get() {
            return 1;
        },
        set(val) {
            this.handleChangePage(val);
        },
      },
    },
    methods: {
      handleSearchChangeWarehouse(searchTerm) {
        this.controllerWarehouse.abort()
        this.$http
          .get("/api/wms/v1/master/warehouses", {
            signal: this.controllerWarehouse.signal,
            params: {
              search: searchTerm,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.warehouses = resp.data.records.map(r => ({...r, text: `${r.code} ${r.name}`}));
              this.$vs.loading.close();
            }
          });
      },
      handleSearchChangeItem(searchTerm) {
        console.log("search", searchTerm)
        // this.cancelTokenItem.cancel()
        this.controllerItem.abort()
        this.$http
          .get("/api/wms/v1/master/shelf-life/item", {
            // cancelToken: this.cancelTokenItem.token,
            signal: this.controllerItem.signal,
            params: {
              search: searchTerm,
              order: "id",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.items = resp.data.records.map(r => ({...r, text: `${r.sku_code} ${r.name}`}));
              // console.log(this.items)
              this.$vs.loading.close();
            }
          });
      },
      handleSearchClearWarehouse() {
        this.warehouses = [];
      },
      handleSearchClearItem() {
        this.items = [];
      },
      handleSave() {
        if (!this.detailData.warehouse) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Warehouse is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      if (!this.detailData.item) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Item is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      if (!this.detailData.in_days) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Days >= is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return
      }
      this.$vs.loading();
        if (this.detailData.id) {
          this.$http
            .put(`/api/wms/v1/master/shelf-life/${this.detailData.id}`, {
              warehouse_id: this.detailData.warehouse.id,
              item_id: this.detailData.item.id,
              in_days: parseInt(this.detailData.in_days),
              type: "Warehouse",
            })
            .then((resp) => {
              if (resp.code == 200) {
                  this.handleClose()
                  this.$vs.loading.close();
              } else {
                  this.$vs.loading.close();
              }
            });
        } else {
          this.$http
            .post("/api/wms/v1/master/shelf-life", {
              warehouse_id: this.detailData.warehouse.id,
              item_id: this.detailData.item.id,
              in_days: parseInt(this.detailData.in_days),
              type: "Warehouse",
            })
            .then((resp) => {
              if (resp.code == 200) {
                  this.handleClose()
                  this.$vs.loading.close();
              } else {
                  this.$vs.loading.close();
              }
            });
        }
      },
      handleSearch(searching) {
        this.table.search = searching;
        this.table.page = 1;
        this.getData();
      },
      handleChangePage(page) {
        this.table.page = page;
        this.getData();
      },
      handleSort(key, active) {
        this.table.order = key;
        this.table.sort = active;
        this.getData();
      },
      handleClose() {
        this.detailData = {}
        this.detailS = false
        this.detail = false
        this.getData();
        // setTimeout(() => this.detail = false, 800)
      },
      getData() {
        console.log("getData")
        this.$vs.loading();
        this.$http
          .get("/api/wms/v1/master/shelf-life", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              type: "Warehouse",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
                this.table.total = resp.data.total_record;
                this.table.totalPage = resp.data.total_page;
                this.table.totalSearch = resp.data.total_record_search;
                this.table.length = resp.data.total_record_per_page;
                this.table.data = resp.data.records;
                this.setStartEnd();
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
          });
      },
      setStartEnd() {
        let valStart =
            this.table.length * this.table.page - this.table.length + 1;
  
        if (valStart > this.table.total) {
            valStart = 1;
        }
        if (this.table.total == 0) {
            valStart = 0;
        }
        let valEnd = this.table.length * this.table.page;
  
        if (valEnd > this.table.total) {
            valEnd = this.table.total;
        }
  
        if (
            this.table.totalSearch < this.table.total &&
            this.table.search != ""
        ) {
            valEnd = this.table.totalSearch;
        }
  
        this.table.start = valStart;
        this.table.end = valEnd;
      },
      handleDetail(id) {
        this.detail = true
        if (id) {
          console.log(id)
          this.$vs.loading();
          this.$http
            .get(`/api/wms/v1/master/shelf-life/${id}`)
            .then((resp) => {
                if (resp.code == 200) {
                  setTimeout(() => this.detailS = true, 1000)
                  this.detailData = resp.data
                  this.detailData.warehouse = {
                    id: this.detailData.warehouse.id,
                    code: this.detailData.warehouse.code,
                    name: this.detailData.warehouse.name,
                    text: `${this.detailData.warehouse.code} ${this.detailData.warehouse.name}`,
                  }
                  this.detailData.item = {
                    id: this.detailData.item.id,
                    sku_code: this.detailData.item.sku_code,
                    name: this.detailData.item.name,
                    text: `${this.detailData.item.sku_code} ${this.detailData.item.name}`,
                  }
                  this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            });
        } else {
          setTimeout(() => this.detailS = true, 1000)
        }
      },
      handleDelete(id) {
        console.log(id)
        this.$vs.loading();
        this.$http
        .delete(`/api/wms/v1/master/shelf-life/${id}`)
        .then((resp) => {
            if (resp.code == 200) {
                this.getData()
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        });
      },
      isNumber: function (evt) {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
    }
  }
  </script>
  
  <style scoped>
  .core {
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out;
  }
  .vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
  }
  .vertical-divider {
    border-left: 1px solid #7367f0;
    min-height: 100px;
    padding: 5px;
  }
  </style>