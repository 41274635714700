<template>
    <vx-card title="Shelf Life">
      <vs-tabs>
        <vs-tab label="Customer">
          <Customer/>
        </vs-tab>
        <vs-tab label="Warehouse">
          <Warehouse/>
        </vs-tab>
        <vs-tab label="Distribution Channel">
          <DistributionChannel/>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </template>
  <script>
  import Customer from "./customer.vue";
  import Warehouse from "./warehouse.vue";
  import DistributionChannel from "./distribution-channel.vue";
  export default {
    components: {
      Customer,
      Warehouse,
      DistributionChannel,
    },
    data() {
      return {
      };
    },
  };
  </script>
  